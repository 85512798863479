<template>
  <div>
    <b-card>
      <div>
        <validation-observer mode="eager" ref="validator">
          <b-form>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Fee percent" label-for="feePercentage">
                  <validation-provider #default="{ errors }" name="feePercentage" rules="required|min_value:0">
                    <b-form-input type="number" id="feePercentage" v-model.number="form.feePercentage" :state="errors.length ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Minimal fee amount" label-for="minFee">
                  <validation-provider #default="{ errors }" name="minFee" rules="required|min_value:0">
                    <b-form-input type="number" id="minFee" v-model.number="form.minFee" :state="errors.length ? false : null" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Action Buttons -->
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
              Update
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
// eslint-disable-next-line
import { required, password, min_value } from '@validations'
export default {
  name: 'FeeEdit',
  components: {
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      feeId: null,
      form: {
        feePercentage: 0,
        minFee: 0,
      },
    }
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchFeeById: 'pgFees/fetchFeeById',
      updateFee: 'pgFees/updateFee',
    }),

    initState() {
      this.registerValidator(this.$refs.validator)

      if (!this.$route.params.id) return

      this.feeId = parseInt(this.$route.params.id, 10)
      this.fetchFeeById(this.feeId)
        .then(response => {
          const { feePercentage, minFee } = response?.data?.data

          this.form = {
            feePercentage,
            minFee,
          }
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      this.update()
    },

    update() {
      this.waitRequest(() => {
        return this.updateFee({ id: this.feeId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fee is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.push('/fee')
            this.form = {}
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
